<template>
  <main class="Transporters">
    <TheSubHeader 
      icon="truck-delivery" 
      title="Transportadoras"
      page-title-tag="Transporters"
      type="filter"
      addnew-link="/transporters/add"
      :actions="['addnew']"
      organizer-gap="1rem"
      organizer-columns="3fr 1fr"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="permissions && permissions.transporters && permissions.transporters.transportersTransferOrdersPageButton"
          icon="dashboard-package-request"
          label="Redespacho e transferência"
          :track="trackSubheaderButtonString('Transporters', 'Transporter Transfer')"
          @click="$router.push('/transporters/transferOrders')"
        />
      </template>
      <template #filter>
        <!-- Transporters -->
        <BaseTreeSelect
          v-model="filters.searchValue"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione"
          multiple
          :select-options="transporters"
          @input="resetSearch()"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.statusSelected"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :select-options="statusOpt"
          @input="resetSearch()"
        />
      </template>
    </TheSubHeader>
    <div class="Transporters__content">
      <div class="Transporters__table">
        <DataTable
          is-sticky
          :header="transportersTable.data.headers"
          :table="transportersTable.data.content"
          @edit="editTransporters"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
  </main>
</template>

<script>
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import { TheSubHeader } from '@/components/organisms'
import { DataTable, Pagination } from '@/components/molecules'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'OrdersImport',
  components: {
    DataTable,
    BaseButton,
    BaseTreeSelect,
    TheSubHeader,
    Pagination
  },
  data() {
    return {
      filters: {
        searchValue: [],
        statusSelected: null,
      },
      storeTransporters: {},
      statusOpt: [{ id: 0, name:'Todos' }, { id: 1, name:'Ativo' }, { id: 2, name:'Inativo' }]
    }
  },
  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      transportersTable: state => state.transporters.transportersTable,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions
    }),
  },
  
  mounted() {
    this.fetchTransporters(null)
    this.search()
  },

  methods:{
    ...mapActions({
      fetchTransporters: 'transporters/fetchTransporters',
      fetchTransportersTable: 'transporters/fetchTransportersTable',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search(){

      const params = {
        obj: {
          id: this.filters.searchValue,
          status_id: this.filters.statusSelected ? this.filters.statusSelected.id : null
        },
        paginate: this.paginate,
        page: this.page
      }

      this.setParameters(this.filters)
      this.fetchTransportersTable(params)
        .then(() => this.setPagination(this.transportersTable))
    },

    editTransporters(data){
      this.openInNewTab(`/transporters/${data.id}`)
    }
  }

}
</script>

<style lang="scss" scoped>
.Transporters {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }
}
</style>